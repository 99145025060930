<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row justify-content-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type client name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: '/client/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="client"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(name)="row">
              <div style="width: 250px">
                <router-link :to="{ path: '/client/edit/' + row.item.id }">
                  {{ row.item.name }}
                </router-link>
                <span class="font-weight-bold">
                  #{{ row.item.client_id }}
                </span>
              </div>
            </template>

            <template #cell(app_name)="row">
              <div style="width: 200px">
                {{ row.item.app_name }}
              </div>
            </template>

            <template v-slot:cell(allow_age_restricted_content)="row">
              {{
                row.item.allow_age_restricted_content ? '&#9989;' : '&#10060;'
              }}
            </template>

            <template v-slot:cell(is_active)="row">
              <div class="text-center">
                {{ row.item.is_active ? '&#9989;' : '&#10060;' }}
              </div>
            </template>

            <template #cell(platform)="row">
              <Platform :platform="row.item.platform_id" />
            </template>

            <template #cell(created)="row">
              <div style="width: 150px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>

            <template #cell(modified)="row">
              <div style="width: 150px">
                {{ row.item.modified ? formatDate(row.item.modified) : '-' }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import Platform from '../../components/platform/platform.vue';
export default {
  name: 'Client',
  components: {
    Platform,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      message: '',
      typing: null,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'name', label: 'Name' },
        { key: 'app_name', label: 'App Name' },
        { key: 'version', label: 'Version' },
        { key: 'lowest_os_version', label: 'Lowest OS Version' },
        { key: 'allow_age_restricted_content', label: 'Restricted Content' },
        { key: 'platform', label: 'Platform' },
        { key: 'app.name', label: 'Application' },
        { key: 'is_active', label: 'Status' },
        { key: 'created', label: 'Created' },
        { key: 'modified', label: 'Modified' },
      ],
      status: '',
      statusOptions: [
        { value: '', text: 'All' },
        { value: 'true', text: 'Active' },
        { value: 'false', text: 'In Active' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getClient();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Clients | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.client.isLoading,
      isError: (state) => state.client.isError,
      totalRows: (state) => state.client.totalRows,
      client: (state) => state.client.items,
    }),
  },
  mounted() {
    this.getClient();
  },
  methods: {
    ...mapActions('client', ['fetchAll']),

    getClient() {
      let payload = {
        name: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchAll(payload);
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchAll({
          name: this.message,
          page: 1,
          limit: this.perPage,
          status: this.status,
        });
      }, 600);
    },

    formatDate(tgl) {
      return moment(tgl).format('DD MMM YYYY HH:mm:ss');
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    onStatusChange() {
      let payload = {
        name: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchAll(payload);
    },
  },
};
</script>
